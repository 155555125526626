import EnvironmentType, { EnvironmentKeysEnum } from "entities/environment";
import { getEnvVariable } from "util/env.util";


export function loadEnvFromFile(): EnvironmentType {

    // AUTH0
    const auth0 = {
        domain: getEnvVariable(EnvironmentKeysEnum.AUTH0_DOMAIN),
        redirectUri: getEnvVariable(EnvironmentKeysEnum.AUTH0_REDIRECT_URI),
        clientId: getEnvVariable(EnvironmentKeysEnum.AUTH0_CLIENT_ID),
        namespace: getEnvVariable(EnvironmentKeysEnum.AUTH0_NAMESPACE),
        audience: getEnvVariable(EnvironmentKeysEnum.AUTH0_AUDIENCE)
    };

    // TIM BACKEND
    const timBackend = {
        domain: getEnvVariable(EnvironmentKeysEnum.TIM_BACKEND_DOMAIN),
    }

    const basePath = getEnvVariable(EnvironmentKeysEnum.BASE_PATH);

    return {
        basePath,
        auth0: auth0,
        timBackend: timBackend
    }

}