

export enum EnvironmentContextsEnum {
    FILE = 'file',
    REMOTE = 'remote',
}

export enum EnvironmentKeysEnum {
    CONTEXT = 'REACT_APP_CONTEXT',
    BASE_PATH = 'REACT_APP_BASE_PATH',
    TENANT_ID = 'REACT_APP_TENANT_ID',
    CLIENT_ID = 'REACT_APP_CLIENT_ID',
    AUTH_REDIRECT_URI = 'REACT_APP_AUTH_REDIRECT_URI',
    AUTH0_DOMAIN = 'REACT_APP_AUTH0_DOMAIN',
    AUTH0_CLIENT_ID = 'REACT_APP_AUTH0_CLIENT_ID',
    AUTH0_REDIRECT_URI = 'REACT_APP_AUTH0_REDIRECT_URI',
    AUTH0_NAMESPACE = 'REACT_APP_AUTH0_NAMESPACE',
    AUTH0_AUDIENCE = 'REACT_APP_AUTH0_AUDIENCE',
    TIM_BACKEND_DOMAIN = 'REACT_APP_TIM_BACKEND_DOMAIN',
}

export default interface EnvironmentType {
    basePath: string,
    auth0: {
        domain: string,
        clientId: string,
        redirectUri: string,
        namespace: string,
        audience: string;
    },
    timBackend: {
        domain: string
    }
}