import EnvironmentType, { EnvironmentContextsEnum, EnvironmentKeysEnum } from "entities/environment";
import { loadEnvFromFile } from "providers/environment/file-loader";
import { createContext, useEffect } from "react";
import { useState } from "react";
import { getEnvVariable } from "util/env.util";

const dummyEnvironment: EnvironmentType = {
    basePath: '/',
    auth0: {
        domain: '',
        clientId: '',
        redirectUri: '',
        namespace: '',
        audience: '',
    },
    timBackend: {
        domain: ''
    }
};

const loadEnv = async (): Promise<EnvironmentType> => {
    const envContext = getEnvVariable(EnvironmentKeysEnum.CONTEXT);
    if (envContext === EnvironmentContextsEnum.REMOTE) {
        throw new Error('Remote environment context not implemented')
    }
    // Fallback to loading env from file
    return loadEnvFromFile();
};

export const EnvironmentContext = createContext<EnvironmentType>(dummyEnvironment);


export default function EnvironmentProvider(props: any) {
    const [env, setEnv] = useState<EnvironmentType | null>(null);

    useEffect(() => {
        loadEnv().then((param) => setEnv(param));
    }, [])

    if (!env) {
        return <></>
    }

    return (
        <EnvironmentContext.Provider value={env!}>
            {props.children}
        </EnvironmentContext.Provider>
    )

}